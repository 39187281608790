import React from 'react'
import { PageProps } from 'gatsby'

import { GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'
import ServiceDetails from '../components/ServiceDetails'
import Footer from '../components/Footer'
import Header from '../components/Header'

const leftFeatureItems = [
  {
    id: 1,
    name: 'PBX Systems',
    description:
      `We work with multiple PBX telephone systems, and filtered out the best providers based their reliability, customer service, calling features, user reviews, and pricing to fit your requirements`,
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'VoIP Solutions',
    description:
      `Cost effective, flexible, and accessible solutions tailored to your business providing greater remote working potential, enhanced communications and organisational mobility.`,
    icon: LightningBoltIcon,
  },
]
const rightFeatureItems = [
  {
    id: 1,
    name: 'Voice Recording Systems',
    description:
      `From a single extension recorder to a system that monitors every incoming and outgoing call from your organisation we offer solutions tailored to your specific call recording needs and compatible with your phone system that meets your business requirements.`,
    icon: ScaleIcon,
  },
  {
    id: 2,
    name: 'Leased Lines',
    description:
      `We work closely with our trusted suppliers to help achieve the best outcome for all your leased line solutions.`,
    icon: MailIcon,
  },
]

const Telephony = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <div className="py-12 bg-white overflow-hidden lg:py-12">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                TELEPHONY
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                Onsite or cloud based – unify and seamlessly integrate your business communication with cost -effective, agile and secure telephony solutions.
              </p>
            </div>

            <ServiceDetails leftFeatures={leftFeatureItems} rightFeatures={rightFeatureItems} />

            
          </div>
        </div>
      </main>
    
      <Footer />
    </div>
  )
}

export default Telephony